<template>
  <div>
    <div class="index-carousel">
      <div class="main masthead position-relative">
        <div class="masthead-overlay"></div>

        <div class="height-100">
          <div class="container">
            <div class="row align-items-center">
              <!-- Left Side Text Content -->
              <div class="col-lg-6 col-md-6">
                <div class="masthead-cont">
                  <h1 class="masthead-title">
                    Welcome to our AI Agents Network!
                  </h1>
                </div>
                <div class="masthead-cont ">
                <h3 class="masthead-subtitle">
                Get tasks done faster with our AI agents—whether it's creating visuals, generating content, or automating jobs. Our agents are here to make your life easier!
                <br> <br> 
                <strong>Start Creating in Seconds:</strong> Pick an agent, give a prompt, and ask AI Assistant to create content — it’s that simple!
                <br> <br> 
                <strong>New Agents Added Regularly:</strong> Discover fresh agents every week to supercharge your workflow.
                <br> <br> 
                </h3>
                </div>

                <b-button 
                  :to="{ name: 'agents'}" 
                  pill 
                  variant="secondary" 
                  class="custom-button" 
                  style="margin-left: 30px; margin-bottom: 0px; margin-top: 10px;">
                  Explore Agents
                </b-button>
              </div>
              
              <!-- Right Side Featured Images Carousel -->
              <div class="col-lg-6 col-md-6">
                <div class="featured-carousel">
                  <carousel 
                    :autoplay="true" 
                    :autoplay-timeout="2500" 
                    :loop="true" 
                    :navigationEnabled="false" 
                    :paginationEnabled="false">
                    <slide v-for="(product, index) in FeaturedImages" :key="index">
                      <div class="item">
                        <b-card class="product-card" no-body>
                          <b-link class="text-body">
                            <b-img 
                              class="carousel-image" 
                              :src="product.imageUrl" 
                              alt="Featured Image"
                            />
                          </b-link>
                        </b-card>
                      </div>
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BContainer,
  BRow,
  BCol,
  BImg,
  BLink,
} from 'bootstrap-vue';
import { Carousel, Slide } from 'vue-carousel';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    BCard,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    BLink,
    Carousel,
    Slide,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('app', {
      Banner: state => state.banner,
      FeaturedImages: state => state.featured_images,
    }),
  },
  mounted() {
    this.loadFeaturedImagesFromStorage();
    // this.getBannerDetails();
    this.fetchFeaturedItems();
  },
  methods: {
    ...mapActions('app', [
      // 'getBannerDetails',
      'fetchFeaturedItems',
      'loadFeaturedImagesFromStorage',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.masthead {
  background: url('~@/assets/images/banner/bg.png'); 
  background-position: 0 0;
  background-repeat: epeat;
  background-size: auto;

}

.masthead-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(130deg, #000 0%, #000 100%);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.77;
}

.masthead-title {
  color: white; 
  font-size: 2.5rem; 
  padding-left: 30px; 
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}

.masthead-subtitle {
  color: white; 
  font-size: 1.4rem; 
  padding-left: 30px; 
  padding-top: 10px;
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}
.masthead-subtitle strong {
  font-weight: bold;
  color: hsl(0, 0%, 100%); /* Highlighted text in a bold, eye-catching color */
}

.masthead-subtitle strong:hover {
  color: #ffffff; /* Slight hover effect for better interaction */
}

.featured-carousel {
  width: 100%; /* Make sure the carousel takes the full width of the container */
  max-height: 600px;
  padding-top: 20px;   
   
}

.item {
  display: flex; /* Use flex to center image */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: auto; /* Ensure the item takes full height */
  max-width: 100%; /* Ensure image doesn't overflow */
  object-fit: cover; 
  padding: 10px;
}

.carousel-image {
  max-width: 100%; /* Ensure the image does not exceed the container width */
  max-height: 100%; /* Ensure the image does not exceed the container height */
  object-fit: contain; /* Ensure the entire image is visible without cropping */
}

/* Responsive behavior */
@media (max-width: 768px) {
  .masthead-title {
    font-size: 3rem; 
    padding-top: 100px;
    padding-right: 40px;
  }

  .masthead-subtitle {
    font-size: 2.5rem;
    padding-right: 40px;
  }

  .featured-carousel {
    padding: 10px;
  }

  .item {
    height: 300px; /* Adjust height for smaller screens */
  }

  .carousel-image {
    max-height: 250px; /* Adjust image height for smaller screens */
  }
}

@media (max-width: 576px) {
  .masthead-title {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 80px;
  }

  .masthead-subtitle {
    font-size: 1.2rem;
  }

  .item {
    height: 250px; /* Adjust for smaller devices */
  }
}
</style>
